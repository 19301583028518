import Swiper from "swiper";

window.boxesServicesSwiper = () => {
  window.boxesServicesIsLoaded = true;
  const sliderBoxesServices = [];
  const windowSizeEvents = () => {
    const boxesServicesModule = document.querySelectorAll('.jsServicesBoxesModule');

    boxesServicesModule.forEach(module => {
      const isLoaded = module.getAttribute('data-swiper');

      if (window.innerWidth < 768 && isLoaded === null) {
        module.setAttribute('data-swiper', 'loaded');
        const swiperElement = module.querySelector('.jsBoxesServicesSwiper');
        const paginationElement = swiperElement.querySelector('.jsBoxesServicesPagination');
        const randomId = Math.random().toString(36).substr(2, 9);

        swiperElement.classList.add(`jsBoxesServicesSwiper-${randomId}`);
        sliderBoxesServices.push(new Swiper(`.jsBoxesServicesSwiper-${randomId}`, {
          slidesPerView: 1.3,
          spaceBetween: 30,
          pagination: {
            el: paginationElement,
            clickable: true
          },
        }));
      }
    });
  };

  windowSizeEvents();

  window.addEventListener('resize', () => {
    window.boxesServicesIsLoaded ? windowSizeEvents() : null;
  });
};