import Swiper from "swiper";

window.topSideSwiper = () => {
  const topSide = new Swiper('.jsTopSideSwiper', {
    autoplay: {
      delay: 5000,
    },
    loop: true,
    pagination: {
      el: '.jsTopSideSwiperPagination',
      clickable: true
    },
  });

  // Hide and stop slider if theere is one slide
  const slider = document.querySelector('.jsTopSideSwiper'),
    pagination = slider.querySelector('.jsTopSideSwiperPagination'),
    slides = pagination.querySelectorAll('.swiper-pagination-bullet'),
    wrapper = slider.querySelector('.swiper-wrapper');

  if (slides.length === 1) {
    wrapper.classList.add("disabled");
    pagination.classList.add("disabled");
  }
};