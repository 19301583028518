window.serviceListEvents = () => {
  const serviceListContainers = document.querySelectorAll('.jsServiceListContainer');

  // Scaling each container to the same height
  serviceListContainers.forEach((container) => {
    const imgs = container.querySelectorAll('img');
    const moduleIsRendered = container.getAttribute('data-rendered');
    let isRendered = false;

    imgs.forEach(img => {
      if (img.complete) {
        isRendered = true
      } else {
        isRendered = false
      }
    })

    // Render each container only once
    if (isRendered && moduleIsRendered === 'false' || moduleIsRendered === 'false') {
      const containerElements = container.querySelectorAll('.jsServiceListElement'),
        background = container.getAttribute('data-bg') || '',
        backgroundHover = container.getAttribute('data-bg-hover') || '',
        color = container.getAttribute('data-color'),
        colorHover = container.getAttribute('data-color-hover'),
        elementParams = {
          image: [],
          title: [],
          text: [],
          color,
          colorHover,
          background,
          backgroundHover
        };

      // get height of each element container
      const getHeightOfEachElem = (containerElements, elementParams) => {
        containerElements.forEach((element) => {
          const elementImage = element.querySelector('.jsServiceListImg').querySelector('img'),
            elementTitle = element.querySelector('.jsServiceListTitle').querySelector('h3'),
            elementText = element.querySelector('.jsServiceListText').querySelector('p');

          elementImage !== null ? elementParams.image.push(elementImage.offsetHeight) : null;
          elementTitle !== null ? elementParams.title.push(elementTitle.offsetHeight) : null;
          elementText !== null ? elementParams.text.push(elementText.offsetHeight) : null;
        });

        elementParams.image = Math.max.apply(null, elementParams.image);
        elementParams.title = Math.max.apply(null, elementParams.title);
        elementParams.text = Math.max.apply(null, elementParams.text);
      }

      // set the same size for each element container
      const setHeightOfEachElem = (containerElements, elementParams) => {
        containerElements.forEach((element) => {
          const elementImage = element.querySelector('.jsServiceListImg'),
            elementTitle = element.querySelector('.jsServiceListTitle'),
            elementText = element.querySelector('.jsServiceListText');

          elementImage !== null ? (elementImage.style.height = elementParams.image + 'px') : null;
          elementTitle !== null ? (elementTitle.style.height = elementParams.title + 'px') : null;
          // elementText !== null ? (elementText.style.height = elementParams.text + 'px') : null;
        });
      }

      // set events on hover each element container
      const setEventsOnHover = (containerElements, background, backgroundHover, color, colorHover, elementParams) => {
        containerElements.forEach((element) => {
          if (element.classList.contains('jsColumnServicesElement') || element.classList.contains('jsBoxesServicesElement')) {
            const elementImage = element.querySelector('.jsServiceListImg') || '',
              image = elementImage.querySelector('img') || '',
              elementImageBasic = elementImage.getAttribute('data-img') || '',
              elementImageHover = elementImage.getAttribute('data-img-hover') || '',
              elementTitle = element.querySelector('.jsServiceListTitle'),
              elementText = element.querySelector('.jsServiceListText'),
              arrow = element.querySelector('.jsServiceListArrow'),
              arrowElement = arrow !== null ? arrow.querySelector('path') : '';
            arrowElement !== '' && color && arrowElement.setAttribute('fill', color);

            element.addEventListener('mouseover', () => {
              if (window.innerWidth > 767) {
                backgroundHover !== '' ? element.style.background = elementParams.backgroundHover : null;
                colorHover !== '' ? elementTitle.style.color = elementParams.colorHover : null;
                colorHover !== '' ? elementText.style.color = elementParams.colorHover : null;
                elementImageHover !== '' ? image.setAttribute('src', elementImageHover) : null;
                arrowElement !== '' && colorHover && arrowElement.setAttribute('fill', colorHover);
              }
            });

            element.addEventListener('mouseout', () => {
              if (window.innerWidth > 767) {
                backgroundHover !== '' ? element.style.background = elementParams.background : null;
                colorHover !== '' ? elementTitle.style.color = elementParams.color : null;
                colorHover !== '' ? elementText.style.color = elementParams.color : null;
                elementImageHover !== '' ? image.setAttribute('src', elementImageBasic) : null;
                arrowElement !== '' && colorHover && arrowElement.setAttribute('fill', color);
              }
            });
          }
        })
      }

      getHeightOfEachElem(containerElements, elementParams);
      setHeightOfEachElem(containerElements, elementParams);
      setEventsOnHover(containerElements, background, backgroundHover, color, colorHover, elementParams);
      container.setAttribute('data-rendered', 'true');
    }
  });
}